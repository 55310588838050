import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import styles from './gokada.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import Layout from '../components/item/layout';
import Resume from '../components/resume';
import Ava from '../components/ava';
import Blockquote from '../components/blockquote';
import Team from '../components/team';
// other
import { meta } from '../../../metaData';

function GoKadaCase() {
  const data = useStaticQuery(graphql`
    query {
      fahimSaleh: file(relativePath: { eq: "fahim-saleh.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 645) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "gokada-screen-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1296) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1p1: file(relativePath: { eq: "gokada-screen-1-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1182) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "gokada-screen-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "gokada-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1401) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "gokada-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 2190) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "gokada-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1257) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "gokada-screen-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 4320) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "gokada-screen-7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen8: file(relativePath: { eq: "gokada-screen-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen9: file(relativePath: { eq: "gokada-screen-9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen10: file(relativePath: { eq: "gokada-screen-10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen11: file(relativePath: { eq: "gokada-screen-11.png" }) {
        childImageSharp {
          fluid(maxWidth: 4320) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen12: file(relativePath: { eq: "gokada-screen-12.png" }) {
        childImageSharp {
          fluid(maxWidth: 4320) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screenLast: file(relativePath: { eq: "gokada-screen-last.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      score={['Ride-sharing', 'Nigeria', 'July, 2018', 'Finished']}
      navBtn="#01C392"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Developing Gokada solution&nbsp;— ride-sharing app"
      subTitle="How Brocoders helped reshape the African taxi services via a digital-driven motorbike hailing platform"
      metaData={meta.gokada}
    >
      <div className={styles.video}>
        <div className="inner">
          <video width="100%" autoPlay loop playsInline muted>
            <source src={`${process.env.GATSBY_STRAPI_CDN}/video/gokada.webm`} type="video/webm" />
            <source src={`${process.env.GATSBY_STRAPI_CDN}/video/gokada.mp4`} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          Fahim Saleh is the founder and CEO of Gokada. Backed by extensive experience in running
          logistics and bike hailing services known as Pathao, he came up with a disruptive idea to
          establish a motorbike hailing company in Lagos, Nigeria. To strengthen his business with
          innovative technology, he contracted Brocoders to build a ride-sharing solution. That’s
          where our partnership story begins.
        </p>
        <div className={styles.avaGroup}>
          <Ava
            img={data.fahimSaleh.childImageSharp.fluid}
            caption={
              <React.Fragment>
                <b>Fahim Saleh</b> <br /> Founder and CEO of Gokada
              </React.Fragment>
            }
          />
        </div>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          It is widely known that the African cities are densely populated, so it’s no wonder there
          are huge problems with traffic jams out there. Thankfully, best international practices
          demonstrate that a motorcycle vehicle might be a silver bullet for solving the issues
          caused by heavy traffic.
        </p>
        <p className={itemStyles.paragraph}>
          That said, Fahim Saleh partnered up with our team to put his ideas into practice and
          create a feature-rich application that could revolutionize the bike hailing sector in
          Lagos.
        </p>
        <p className={itemStyles.paragraph}>
          As a pathfinder in delivering a ride-sharing innovation in Nigeria, Fahim came to us with
          a working MVP that was developed by another IT company in order to check whether his
          business concept was viable and was in demand in the first place.
        </p>
        <p className={itemStyles.paragraph}>
          Based on the target audiences’ feedback to rebuild and enhance the MVP, Fahim visited our
          office with his team to combine our forces and design a state-of-the-art solution that can
          meet all the end-users’ requirements and needs. He shared his vision of the final product
          and provided us with some preliminary drafts and mockups so we could get an insight into a
          project.
        </p>
        <Resume
          services={['mvp', 'product', 'mobileDev']}
          technology={['react', 'nodejs', 'reactNative']}
        />
      </div>
      <Blockquote
        img={data.fahimSaleh.childImageSharp.fluid}
        text="“Brocoders is a very productive and effective team. We were happy to meet them in Lagos, where they came to meet Gokada team and test application in a real-time.”"
        author={
          <React.Fragment>
            <b> Fahim Saleh</b> <br />
            Founder and CEO of Gokada
          </React.Fragment>
        }
      />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Key challenges</h2>
        <p className={itemStyles.paragraph}>
          To cover all details and acquire invaluable info, we started from workshops with the
          client, which, in turn, inspired our experts and significantly boosted the entire
          workflow. We worked in close collaboration with the Gokada professionals and split the
          tasks between our teams to enable a higher development speed.
        </p>
        <h3 className={itemStyles.subtitle}>
          Cross-platform mobile app for both drivers and passengers
        </h3>
        <p className={itemStyles.paragraph}>
          Among other crucial challenges set before us by the client, we had to update a general
          logic of the app to ensure a smooth and hassle-free development process, as well as
          reinvent the entire project’s design to provide an outstanding user experience.
        </p>
      </div>
      <div className={clsx(styles.appStore, itemStyles.container)}>
        <div className={clsx(styles.storeContainer)}>
          <Img fluid={data.screen1.childImageSharp.fluid} className={styles.screen1} alt="" />
        </div>
        <div className={clsx(styles.storeContainer)}>
          <Img fluid={data.screen1p1.childImageSharp.fluid} className={styles.screen1p1} alt="" />
        </div>
      </div>
      <div className={itemStyles.inner}>
        <h3 className={itemStyles.subtitle}>Team composition</h3>
        <p className={itemStyles.paragraph}>
          Once we filled in the blanks and came up with a further strategy, we set up a team of the
          following on-site specialists: one backend developer, two React Native developers, a QA
          engineer and a project manager. Later, we had to empower our team with additional experts
          since the project was rapidly evolving, and we should deal with a greater scope of tasks.
          Eventually, our team was comprised of:
        </p>
        <Team
          color="#00C795"
          list={[
            { count: 3, title: 'Backend\ndevelopers' },
            { count: 2, title: 'Frontend\ndevelopers' },
            { count: 1, title: 'QA\nengineer' },
            { count: 1, title: 'Project\nmanager' },
          ]}
        />
        <p className={itemStyles.paragraph}>
          The previous projects were built on a separate basis, so we decided to develop a single
          cross-platform mobile app for both drivers and passengers at the same time. Such an
          approach allowed for implementing new features and updating the platform in a fast and
          simplified manner for both Android and iOS, as well as for all parties in one go.
        </p>
      </div>
      <figure className={itemStyles.container}>
        <Img fluid={data.screen2.childImageSharp.fluid} className={styles.screen2} alt="" />
        <figcaption className={clsx('inner-container', itemStyles.inner, styles.caption)}>
          Photo from IG @gokadang
        </figcaption>
      </figure>
      <div className={itemStyles.inner}>
        <h3 className={itemStyles.subtitle}>Admin panel</h3>
        <p className={itemStyles.paragraph}>
          We also had to create a multi-functioning admin panel and integrate it with a working
          platform driven by the client’s feedback and shared drafts. Based on our in-depth research
          of the project, we made the list of all features that an admin panel must include and
          prepared a few high-fidelity prototypes to showcase the work done. In fact, it was the
          most vital task to accomplish in the first place since it provided the means for our
          client to:
        </p>
        <ul className={itemStyles.bullets}>
          <li>Manage and control all processes and involved parties (drivers and users)</li>
          <li>Acquire insights into user behavior patterns (ride requests and cancellations)</li>
          <li>Track transactions</li>
          <li>Provide easy-to-use tools for managers</li>
          <li>Monitor real-time status and statistics on drivers</li>
          <li>Understand a load of available resources at any time</li>
          <li>Gather invaluable info for making the right business decisions</li>
          <li>View the history of rides</li>
        </ul>
      </div>
      <figure className={clsx(styles.imgGroup, itemStyles.container)}>
        <Img
          fluid={data.screen3.childImageSharp.fluid}
          className={styles.screen3}
          style={{ flex: '0 1 25%', overflow: 'initial' }}
          imgStyle={{ height: 'auto', top: '10%', right: '-60px', width: 'calc(100% + 60px)' }}
          alt=""
        />
        <Img
          fluid={data.screen4.childImageSharp.fluid}
          className={styles.screen4}
          style={{ flex: '1 1 50%', zIndex: 2 }}
          imgStyle={{ top: '30px', left: '30px', width: 'calc(100% - 60px)', height: 'auto' }}
          alt=""
        />
        <Img
          fluid={data.screen5.childImageSharp.fluid}
          className={styles.screen5}
          style={{ flex: '0 1 25%', overflow: 'initial' }}
          imgStyle={{
            height: 'auto',
            top: 'auto',
            bottom: '60px',
            left: '-60px',
            width: 'calc(100% + 60px)',
          }}
          alt=""
        />
      </figure>
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Since there was an existing admin panel, we didn’t have to create a new custom-designed
          version from scratch, which allowed us to reduce the development time and concentrate more
          on other important tasks during the first stages.
        </p>
      </div>
      <Img
        fluid={data.screen6.childImageSharp.fluid}
        className={itemStyles.container}
        style={{ marginTop: 0 }}
        alt=""
      />
      <div className={itemStyles.inner}>
        <h3 className={itemStyles.subtitle}>Mapping service</h3>
        <p className={itemStyles.paragraph}>
          Another challenge to take care of was the choice of a mapping service that can fit our
          platform best.
        </p>
        <p className={itemStyles.paragraph}>
          Since the primary flow was deeply connected to a location data function, it was crucial to
          pick the one that had cost-effective payment plans so that we could reduce expenses
          related to GPS tracking and data location features. A Google Map Service API was too
          expensive to afford for all internal processes, so we tried a few different mapping
          services to find out which is the most appropriate. We compared OpenStreet maps and Mapbox
          to Google maps based on the data we worked with - distance and duration. Mapbox turned up
          to be a decent option in certain circumstances, so we decided to utilize the combination
          of two mapping services for a different scope of application.
        </p>
        <h3 className={itemStyles.subtitle}>Integrating Paystack payment system</h3>
        <p className={itemStyles.paragraph}>
          We faced a similar issue when it came to the local payment systems. We needed to integrate
          the app with a platform that supported the local African payment methods, including a
          cashless settlement.{' '}
        </p>
        <p className={itemStyles.paragraph}>
          Unfortunately, most of the local payment systems lacked the required functionality and
          compatibility. Nevertheless, we managed to find and implement a working solution that had
          robust functionality and could fit our specific requirements - a{' '}
          <a className={styles.paystackLink} href="https://paystack.com/">
            Paystack payment system.
          </a>
        </p>
      </div>
      <figure className={clsx(styles.figure, itemStyles.container)}>
        <Img fluid={data.screen7.childImageSharp.fluid} className={styles.screen7} />
        <div className={styles.screen8}>
          <Img fluid={data.screen8.childImageSharp.fluid} alt="" />
          <figcaption className={styles.caption}>Photo from IG @gokadang</figcaption>
        </div>
      </figure>
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Delivered solution</h2>
        <p className={itemStyles.paragraph}>
          Thanks to our seasoned specialists and extensive software development expertise, we
          successfully faced up to the challenges and did create a bike hailing platform that met
          all the customer’s expectations.
        </p>
        <p className={itemStyles.paragraph}>
          We renovated an underlying logic of the flow, as well as built a whole new app design to
          ensure the end-users could enjoy a captivating and user-friendly interface. Drivers,
          passengers and the Gokada team members ended up with intuitive and easy-to-use dashboards
          that provided trouble-free navigation across all elements and free access to all key
          functionalities.
        </p>
        <h3 className={itemStyles.subtitle}>
          Here are some of the core features that users could benefit from:
        </h3>
        <ul className={itemStyles.bullets}>
          <li>
            Passengers could quickly request an available ride based on a driver’s profile and get a
            fair cost calculation.
          </li>
          <li>
            All users could see their trips history, while the admins gathered info on all requested
            and canceled rides.
          </li>
          <li>
            Drivers could promptly make a ride request with just a few clicks and share their trips
            with others in real-time.
          </li>
          <li>
            All users could enjoy a cashless payment system benefits that made any journey way
            comfier for any party.
          </li>
          <li>
            We implemented GPS-driven maps and geo-location features to enable optimal routes to the
            destination points while avoiding traffic jams.
          </li>
          <li>
            The Gokada team ended up with enhanced business management thanks to an admin panel that
            allowed for acquiring in-depth insights, monitoring vital stats, such as the details of
            trips and users, and more.
          </li>
        </ul>
      </div>
      <div className={itemStyles.container}>
        <Img fluid={data.screen11.childImageSharp.fluid} alt="" />
        <Img fluid={data.screen12.childImageSharp.fluid} alt="" />
      </div>
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>The results</h2>
        <p className={itemStyles.paragraph}>
          The first product release was executed at the beginning of 2019. In fact, the platform
          already had some active drivers that were eager to start accepting customers’ requests.
        </p>
        <p className={itemStyles.paragraph}>
          During that period, we worked closely with the drivers’ and users’ feedback to analyze
          which features or updates should be included in the next versions. All the further updates
          allowed us to increase the amount of daily requested rides by{' '}
          <b>2,5 times during the first six months</b>. Meanwhile, the total amount of{' '}
          <b> daily canceled rides was decreased in half during this period</b>, and one more time
          during the next six months.
        </p>
        <p className={itemStyles.paragraph}>
          At the end of the year, we ended up with some fascinating outcomes:
        </p>
        <div className={styles.result}>
          <dl>
            <dt>1.5M</dt>
            <dd>ride requests in total</dd>
          </dl>
          <dl>
            <dt>325K</dt>
            <dd>of which were completed by the assigned 1000+ drivers</dd>
          </dl>
          <dl>
            <dt>$5.3M</dt>
            <dd>Series A round</dd>
          </dl>
        </div>
      </div>
      <div className={clsx(styles.figure2, itemStyles.container)}>
        <Img
          fluid={data.screen9.childImageSharp.fluid}
          style={{ flex: '0 0 55%' }}
          imgStyle={{ height: 'auto', top: '80px' }}
          alt=""
        />
        <div style={{ flex: '0 0 45%' }}>
          <Img fluid={data.screen10.childImageSharp.fluid} alt="" />
          <figcaption className={styles.caption}>Photo from IG @gokadang</figcaption>
        </div>
      </div>
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Needless to say, such remarkable results inspired our team to put the shoulder to the
          wheel. Based on gathered data, we managed to find and fix critical bottlenecks, while
          preventing their harmful impact on the further development stages.
        </p>
        <p className={itemStyles.paragraph}>
          Our team kept working closely with the Gokada’s managers and their feedback after the
          release date. Our goal was to make the admin panel more optimized and simplified so
          managers could effectively solve basic operational tasks and provide quick support to all
          app users.
        </p>
        <p className={itemStyles.paragraph}>
          We also had a few scheduled projects, such as an improvement of the payment system and an
          integration of the recruitment add-on module that was mainly focused on converting leads
          into drivers. That said, drivers could go through online tutorials and other training
          materials, pass a test and get a driver’s license in a short time.
        </p>
        <p className={itemStyles.paragraph}>
          Unfortunately, the Lagos government implemented the ban on passenger motorcycles in
          February, so Gokada had to pivot the entire business to a delivery service. That’s where
          our fruitful mutual collaboration has ended, thus excluding the possibility of
          accomplishing all the remaining goals.
        </p>
      </div>
      <Img
        fluid={data.screenLast.childImageSharp.fluid}
        className={styles.lastScreen}
        style={{ overflow: 'initial', marginTop: '112px' }}
        alt=""
      />
    </Layout>
  );
}

export default GoKadaCase;
